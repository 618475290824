import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BarLoader from "react-spinners/BarLoader";

import { isBrowser } from "../../utils/constants";

const override = {
  display: "block",
  position: "fixed",
  top: "0",
  zIndex: "1000000001",
};

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000001;

  span {
    display: block important;
    position: fixed !important;
    top: 0 !important;
    z-index: 1000001;
  }
`;

export const NetworkLoader = ({ show }) => {
  const [width, setWidth] = useState(null);

  const updateWindowWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (isBrowser) {
      updateWindowWidth();

      window.addEventListener("resize", updateWindowWidth);

      return () => {
        window.removeEventListener("resize", updateWindowWidth);
      };
    }
  }, []);

  return show ? (
    <Loader id="network-loader">
      <BarLoader
        // css={override}
        cssOverride={override}
        height={5}
        color="#1DB4D4"
        loading={show}
        width={width || 1920}
      />
    </Loader>
  ) : null;
};

NetworkLoader.defaultProps = {
  show: true,
};
