import React from "react";
import styled, { css } from "styled-components";

import { Flex, Box, Text, Heading, Image } from "../../../globals/UIKit";
import { StyledStoreCart } from "../styles/StyledStoreCart";
import { BackButton } from "../ProductDetails/BackButton";
import { SwagStorePaperform } from "../Checkout/SwagStorePaperForm";
import { CouponIcon } from "./CouponIcon.js";

const StyledMainWrapper = styled.div`
  &::before {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background: #ffffff;
    content: " ";
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    width: 50%;
    box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);
  }
  .mainBg {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: rgb(244, 248, 251);
  }

  .lineThrough {
    text-decoration: line-through;
  }

  @media (max-width: 821px) {
    &::before {
      width: 100%;
      background: #ffff;
    }
  }
`;

const StyledCheckoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .wd45 {
    width: 45%;
  }

  @media (max-width: 821px) {
    .order-summary-div {
      display: none;
    }

    .wd45 {
      width: 100%;
      background: #fff;
      margin-left: -20px;
    }
  }
`;

export const ShippingCheckout = ({ location }) => {
  const getCartItemsList = () => {
    if (
      location &&
      location.state &&
      location.state?.cartItems &&
      location.state?.cartItems?.length &&
      location.state?.cartItems?.length > 0
    ) {
      return (
        <>
          {location.state.cartItems.map(
            ({ product_img, product_name, product_price }) => (
              <Flex
                paddingTop="20px"
                width="100%"
                alignItems="flex-start"
                key={product_name}
              >
                <Image
                  src={product_img}
                  alt="Shipping"
                  id="shipping-img"
                  width="48px"
                  height="48px"
                  borderRadius="12px"
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  pl="16px"
                >
                  <Text
                    color="#23303D"
                    fontSize="16px"
                    // fontWeight="500"
                    fontWeight={700}
                    lineHeight="1.5"
                    className="total-desc-p"
                    pt="5px"
                  >
                    {product_name}
                  </Text>
                  <Flex pt="5px">
                    <Text
                      color="#23303D"
                      fontSize="16px"
                      // fontWeight="500"
                      fontWeight={700}
                      lineHeight="1.5"
                      className="total-desc-p lineThrough"
                      pr="10px"
                    >
                      {`${product_price / 100} USD`}
                    </Text>
                    <Text
                      color="#23303D"
                      fontSize="16px"
                      // fontWeight="500"
                      fontWeight={700}
                      lineHeight="1.5"
                      className="total-desc-p"
                    >
                      0 USD
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )
          )}
        </>
      );
    }
  };

  return (
    <StyledMainWrapper>
      <div className="mainBg" />
      <StyledStoreCart>
        <StyledCheckoutWrapper>
          <div className="wd45 order-summary-div">
            <BackButton
              redirectTo="/cart/"
              linkText="Back to shopping cart"
              pl0
            />
            <Heading
              fontSize="36px"
              fontWeight="700"
              color="#23303D"
              lineHeight="1.25"
              mt="36px"
            >
              0rder Summary
            </Heading>
            {getCartItemsList()}
            <Flex
              mt="16px"
              pt="16px"
              justifyContent="space-between"
              borderTop="1px solid hsla(0,0%,10%,.1)"
              ml="64px"
            >
              <Flex
                width="auto"
                bg="hsla(0,0%,10%,.1)"
                borderRadius="6px"
                height="36px"
                justifyContent="flex-start"
                px="12px"
                // minWidth="200px"
                // width="220px"
                // width="auto"
              >
                <CouponIcon />
                <Text
                  ml="12px"
                  color="rgba(26, 26, 26, 0.9)"
                  fontSize="14px"
                  fontWeight="500"
                >
                  {location?.state?.coupon}
                </Text>
              </Flex>
              <Text color="hsla(0,0%,10%,.5)">Coupon Applied</Text>
            </Flex>
            <Flex
              pl="64px"
              mt="24px"
              justifyContent="space-between"
              width="100%"
            >
              <Text color="hsla(0,0%,10%,.5)">International Shipping</Text>
              <Text color="hsla(0,0%,10%,.5)">0 USD</Text>
            </Flex>
            <Flex
              pl="64px"
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
              paddingTop="24px"
            >
              <Flex
                borderTop="1px solid hsla(0,0%,10%,.1)"
                width="100%"
                justifyContent="space-between"
                pt="16px"
              >
                <Text
                  color="#23303D"
                  fontSize="16px"
                  // fontWeight="500"
                  fontWeight={700}
                  lineHeight="1.5"
                  className="total-desc-p"
                >
                  Total due
                </Text>
                <Text
                  color="#23303D"
                  fontSize="16px"
                  // fontWeight="500"
                  fontWeight={700}
                  lineHeight="1.5"
                  className="total-desc-p"
                >
                  0 USD
                </Text>
              </Flex>
            </Flex>
          </div>
          <Box
            className="wd45"
            pl="35px"
            css={css`
              @media (min-width: 820px) and (max-width: 1200px) {
                padding-left: 0;
              }
            `}
          >
            <SwagStorePaperform location={location} />
          </Box>
        </StyledCheckoutWrapper>
      </StyledStoreCart>
    </StyledMainWrapper>
  );
};
