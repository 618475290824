import React from "react";

export const CouponIcon = () => (
  <svg
    className="InlineSVG Icon Text-color--default AppliedDiscount-icon Icon--sm"
    focusable="false"
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="#6d6f6f"
  >
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.76372 0.441305L11.7795 6.4757C12.0735 6.76867 12.0735 7.24365 11.7795 7.53662L7.52046 11.7803C7.22643 12.0732 6.74973 12.0732 6.4557 11.7803L0.435789 5.74171C0.156657 5.46172 0 5.08308 0 4.68843V1.50036C0 0.671734 0.674166 0 1.50579 0H4.69951C5.09908 0 5.48213 0.158844 5.76372 0.441305ZM2.625 3.75C3.24632 3.75 3.75 3.24632 3.75 2.625C3.75 2.00368 3.24632 1.5 2.625 1.5C2.00368 1.5 1.5 2.00368 1.5 2.625C1.5 3.24632 2.00368 3.75 2.625 3.75Z"
      fill="#6d6f6f"
    ></path>
  </svg>
);
