import React from "react";
import { navigate } from "gatsby";

import { isBrowser, OG_IMAGE } from "../utils/constants";
import Layout from "../components/store/Layout";
import SEO from "../components/seo";
import { ShippingCheckout } from "../components/store/ShippingCheckout";
import { NetworkLoader } from "../components/NetworkLoader";

const canonicalUrl = "https://store.hasura.io/checkout/";

const App = ({ location }) => {
  if (isBrowser) {
    if (!location.state?.coupon) {
      navigate("/cart");

      return <NetworkLoader />;
    }
  }

  return (
    <Layout location={location}>
      <SEO
        title="Checkout | Hasura Swag Store"
        description="Our favourite Hasura designs now in merch"
        meta={OG_IMAGE}
        canonicalLink={canonicalUrl}
      />
      <ShippingCheckout location={location} />
    </Layout>
  );
};

export default App;
