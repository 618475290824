import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import styled from "styled-components";

import { NetworkLoader } from "../../NetworkLoader";
import { ADD_SWAG_ORDER } from "../../../graphql/mutation";
import { STORE_ORDER_SUCCESS_PAGE_URL } from "../../../utils/constants";
import { isBrowser } from "../../../utils/constants";
import { clearSwagStoreCart } from "../storeHelper";

const StyledSwagStoreForm = styled.div`
  width: 100% !important;
  max-width: 450px;

  .swag-store-paperform-wrapper {
    width: 100% !important;
  }
`;

// ******************************** //

const formId = "hf-swag-store-orders";

const styleClass = "swag-store-paperform-wrapper";

// ******************************** //

export const SwagStorePaperform = ({ location }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [isLoading, updateLoadingStatus] = useState(false);

  const embedDivRef = useRef(null);

  const coupon = location?.state?.coupon;

  const [addSwagStoreOrder, { loading: swagStoreLoading }] = useMutation(
    ADD_SWAG_ORDER,
    {
      onCompleted(data) {
        if (data) {
          if (
            data?.insert_orders &&
            data.insert_orders?.returning &&
            data.insert_orders.returning?.length > 0 &&
            data.insert_orders.returning[0]?.order_id
          ) {
            clearSwagStoreCart();
            // Navigate user to success screen
            return navigate(STORE_ORDER_SUCCESS_PAGE_URL, {
              state: {
                isOrderSuccessfull: true,
              },
              replace: true,
            });
          }
        }
      },
    }
  );

  useEffect(() => {
    if (isBrowser) {
      const existingEmbed = document.getElementById("paperform_embed");

      if (existingEmbed) {
        setIsLoaded(true);
        return;
      }

      const script = document.createElement("script");

      script.id = "paperform_embed";

      script.src = "https://forms.hasura.io/__embed.min.js";

      script.onreadystatechange = () => {
        if (this.readyState === "complete" || this.readyState === "loaded") {
          setIsLoaded(true);
        }
      };

      script.onload = () => setIsLoaded(true);

      document.body.prepend(script);

      return () => script.remove();
    }
  }, []);

  // ****************************** //

  function handleFormSubmit({ detail }) {
    if (isBrowser) {
      updateLoadingStatus(true);

      const { data } = detail;

      const name = data.find((d) => d.label === "Full Name")?.value;

      const company = data.find((d) => d.label === "Company")?.value;

      const address = data.find((d) => d.label === "Address")?.value;

      const addressLineTwo = data.find(
        (d) => d.label === "Address Line 2"
      )?.value;

      const city = data.find((d) => d.label === "City")?.value;

      const state = data.find((d) => d.label === "State")?.value;

      const postalCode = data.find((d) => d.label === "Postal Code")?.value;

      const country = data.find((d) => d.label === "Country")?.value;

      const email = data.find((d) => d.type === "email")?.value;

      const telephone = data.find((d) => d.label === "Telephone")?.value;

      // const itemId = data.find((d) => d.label === "Item ID")?.value;

      // const sizeId = data.find((d) => d.label === "Size")?.value;

      // const quantity = data.find((d) => d.label === "Quantity")?.value;

      const orderTotal = location?.state?.totalCartPrice * 100;

      const getAllCartItems = (cartData) => {
        let lineItemsArr = [];

        if (cartData && cartData.length > 0) {
          cartData.forEach((item) => {
            lineItemsArr.push({
              item_id: item?.product_id,
              size_id: item?.apparel_size_id,
              quantity: item?.product_count,
            });
          });
        }

        return lineItemsArr;
      };

      const getCartOrderMutationVarObject = (cartData) => {
        let orderObjArr = [];

        if (cartData && cartData.length > 0) {
          cartData.forEach((cartItem) => {
            const newStock =
              cartItem?.stock_available - cartItem?.product_count;

            if (newStock <= 0) {
              orderObjArr.push({
                where: {
                  price_id: {
                    _eq: cartItem?.price_id,
                  },
                },
                _set: {
                  stock_available: 0,
                  isAvailable: false,
                },
              });
            } else if (newStock < 5) {
              orderObjArr.push({
                where: {
                  price_id: {
                    _eq: cartItem?.price_id,
                  },
                },
                _set: {
                  stock_available: newStock,
                  isAvailable: false,
                },
              });
            } else {
              orderObjArr.push({
                where: {
                  price_id: {
                    _eq: cartItem?.price_id,
                  },
                },
                _set: {
                  stock_available: newStock,
                },
              });
            }
          });
        }

        return orderObjArr;
      };

      // Line Items ******* //

      const lineItems = getAllCartItems(location.state.cartItems);

      const cartOrder = getCartOrderMutationVarObject(location.state.cartItems);

      // return null;

      // Mutation for adding order to Hasura Cloud Instance

      addSwagStoreOrder({
        variables: {
          campaign_id: "306592",
          external_id: "0035A00003nJleEQAS",
          name: name,
          coupon_used: coupon,
          address: address,
          address2: addressLineTwo,
          company: company,
          city: city,
          state: state,
          zip: postalCode,
          country: country,
          email: email,
          phone: telephone,
          lineitems: lineItems,
          order_total: `${orderTotal}`,
          cartOrder: cartOrder,
        },
      });
    }
  }

  // ****************************** //

  useEffect(() => {
    const refCurrValue = embedDivRef?.current;

    if (isBrowser && refCurrValue) {
      refCurrValue.addEventListener("PaperformSubmission", handleFormSubmit);

      return () =>
        refCurrValue.removeEventListener(
          "PaperformSubmission",
          handleFormSubmit
        );
    }
  }, []);

  // ****************************** //

  const isNetworkLoaderActive = isLoading || swagStoreLoading;

  return (
    <StyledSwagStoreForm>
      {isNetworkLoaderActive && <NetworkLoader />}
      <div className={styleClass}>
        {!isLoaded && <NetworkLoader />}
        <div
          data-prefill-inherit="1"
          data-no-scroll="1"
          ref={embedDivRef}
          id={formId}
          data-paperform-id={formId}
          data-spinner="1"
        />
      </div>
    </StyledSwagStoreForm>
  );
};
