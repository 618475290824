import { gql } from "@apollo/client";

export const ADD_SWAG_ORDER = gql`
  mutation addSwagStoreOrder(
    $coupon_used: String!
    $campaign_id: String!
    $external_id: String!
    $name: String!
    $address: String!
    $address2: String
    $company: String
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $email: String!
    $phone: String
    $lineitems: json!
    $order_total: String!
    $cartOrder: [products_updates!]!
  ) {
    insert_orders(
      objects: [
        {
          campaign_id: $campaign_id
          external_id: $external_id
          name: $name
          coupon_used: $coupon_used
          address: $address
          address2: $address2
          company: $company
          city: $city
          state: $state
          zip: $zip
          country: $country
          email: $email
          phone: $phone
          lineitems: $lineitems
          order_total: $order_total
        }
      ]
    ) {
      returning {
        order_id
      }
    }
    update_coupons(
      where: { coupon: { _eq: $coupon_used } }
      _set: { isRedeemed: true, isActive: false }
    ) {
      returning {
        isRedeemed
      }
    }
    update_products_many(updates: $cartOrder) {
      returning {
        display_name
        stock_available
      }
    }
  }
`;

export const UPDATE_COUPON_STATUS = gql`
  mutation updateCouponStatus($coupon: String!) {
    update_coupons(
      where: { coupon: { _eq: $coupon } }
      _set: { isRedeemed: true, isActive: false }
    ) {
      returning {
        isRedeemed
      }
    }
  }
`;
